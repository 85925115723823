import React from 'react'
import LayoutContainer from 'components/Common/LayoutContainer'
import PaymentMethods from './PaymentMethods'
import FollowUsLinks from './FollowUsLinks'
import AppLinks from './AppLinks'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import useWhiteLabelAppConfig from 'hooks/useWhiteLabelAppConfig'

const Container = styled.div`
  display: grid;
  grid-template: auto auto auto / auto;
  place-items: center;
  gap: ${rem(32)};

  ${mediaQueryUp.tablet} {
    gap: ${rem(16)};
    grid-template: auto / 1fr 1fr 1fr;
    place-items: start;
  }
`

interface Props {
  className?: string
}

function TopSection({ className }: Props) {
  const whiteLabelAppConfig = useWhiteLabelAppConfig()

  return <LayoutContainer className={className}>
    <Container>
      <FollowUsLinks />
      {!whiteLabelAppConfig.isApp && <AppLinks />}
      <PaymentMethods />
    </Container>
  </LayoutContainer>
}

export default TopSection
