import React from 'react'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Group from 'components/utils/Group'
import Image from 'components/Common/Image'

function AppLinks() {
  return (
    <VerticalSpacer gap={16}>
      <BodyText variant="large" as="h4" weight="bold" align="center" tabletAlign="start">
        Download Our Free Mobile App
      </BodyText>
      <Group direction="horizontal" horizontalAlign="center" tabletHorizontalAlign="start" gap={8}>
        <ClickableLink href="https://app.luxuryescapes.com/3WHB/1aab092" target="_blank" rel="noopener">
          <Image id="App_Store_suxzv1" alt="Download the iPhone app" width={158}/>
        </ClickableLink>
        <ClickableLink href="https://app.luxuryescapes.com/3WHB/feb04c69" target="_blank" rel="noopener">
          <Image id="Google_Play_o1xn2t" alt="Download the Android app" width={158}/>
        </ClickableLink>
      </Group>
    </VerticalSpacer>
  )
}

export default AppLinks
