import LayoutContainer from 'components/Common/LayoutContainer'
import Pane from 'components/Common/Pane'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { mediaQueryUp } from 'components/utils/breakpoint'
import GeoContext from 'contexts/geoContext'
import getStaticPagesByType from 'lib/utils/getStaticPagesByType'
import { rem } from 'polished'
import React, { useContext } from 'react'
import styled from 'styled-components'
import Accreditations from '../Accreditations'
import FooterItemList from '../FooterItemList'
import FooterListItemsGrid from '../FooterListItemsGrid'
import FooterList from '../FooterList'

const Container = styled(Pane)`
  border-top: 1px solid ${props => props.theme.palette.neutral.default.five};
  padding: ${rem(40)} 0;
`

const OfficeLocation = styled(BodyText)`
  padding-inline: ${rem(8)};
`

const CopyrightText = styled.div`
  text-align: center;

  ${mediaQueryUp.tablet} {
    text-align: right;
  }
`

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.palette.neutral.default.five};
  border: none;
`

const LogosAndCopyrights = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(32)};
  align-items: center;

  ${mediaQueryUp.tablet} {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
  }
`

const agentHubHelpItemTypes = [
  'termsAndConditions',
  'privacyPolicy',
]

function AgentHubGuestFooter() {
  const agentHubHelpItems = getStaticPagesByType(agentHubHelpItemTypes)

  const currentYear = new Date().getFullYear()
  const { currentRegionCode } = useContext(GeoContext)
  const showAtolProtection = currentRegionCode === 'GB'

  return (
    <Container type="light" className="hidden-print">
      <LayoutContainer>
        <VerticalSpacer gap={56}>
          <FooterListItemsGrid>
            <FooterItemList title="Help" items={agentHubHelpItems} />
            <FooterList title="Head Office">
              <OfficeLocation variant="medium">
                Level 4, 68 Clarke Street, Southbank, VIC{'\u00a0'}3006.
                <br /><br />
                Australian owned and operated.
              </OfficeLocation>
            </FooterList>
          </FooterListItemsGrid>
          <Divider />
          <LogosAndCopyrights>
            <Accreditations md={8} showAtolProtection={showAtolProtection} showProductReviewWinner={false} />
            <CopyrightText>
              <BodyText variant="small">Copyright © {currentYear} LE Agent Hub</BodyText>
              <BodyText variant="small">All rights reserved.</BodyText>
            </CopyrightText>
          </LogosAndCopyrights>
        </VerticalSpacer>
      </LayoutContainer>
    </Container>
  )
}

export default AgentHubGuestFooter
