import React, { useState } from 'react'
import { take } from 'lib/array/arrayUtils'
import TextButton from 'components/Luxkit/Button/TextButton'
import styled from 'styled-components'
import { CityDestination, CountryDestination, DestinationNavItemType } from 'components/Common/TopDestinationsList/TopDestinationList'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TextLink from 'components/Luxkit/TextLink'
import VerticalSpacer from '../Spacing/VerticalSpacer'

const DestinationItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: ${rem(30)};

  ${mediaQueryUp.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${rem(40)};

  ${mediaQueryUp.tablet} {
    align-items: center;
    margin-top: ${rem(24)};
  }
`

interface Props {
  destinations: Array<CountryDestination | CityDestination>;
  toShowByDefault: number;
  destinationType: DestinationNavItemType;
}

function TopDestinations({ destinations, toShowByDefault, destinationType }: Props) {
  const haveMoreToShow: boolean = destinations.length > toShowByDefault
  const [isOpenAllDestinations, setOpenAllDestinations] = useState<boolean>(false)

  return (
    <>
      <DestinationItemsContainer>
        {take(destinations, isOpenAllDestinations ? destinations.length : toShowByDefault).map(destination =>
          <VerticalSpacer gap={8} key={destination.name}>
            <BodyText colour="neutral-one" variant="medium" weight="bold">{destination.name}</BodyText>
            <TextLink size="small" variant="black" weight="regular" to={destination.link}>
              Hotels & Resorts
            </TextLink>
          </VerticalSpacer>)
        }
      </DestinationItemsContainer>
      { haveMoreToShow && <ButtonWrapper>
        <TextButton
          onClick={() => setOpenAllDestinations(!isOpenAllDestinations)}
          kind="secondary"
          size="large"
        >
          {isOpenAllDestinations ?
            'Show less' :
              `Show ${destinations.length} ${destinationType.toLowerCase()}`
            }
        </TextButton>
      </ButtonWrapper>
      }
    </>
  )
}

export default TopDestinations
