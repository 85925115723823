import { rem } from 'polished'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${rem(224)}, 1fr));
  row-gap: ${rem(32)};
`

interface Props extends PropsWithChildren {
}

function FooterListItemsGrid({ children }: Props) {
  return <Container>{children}</Container>
}

export default FooterListItemsGrid
