import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { rem } from 'polished'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

const ListTitle = styled(BodyText)`
  padding-inline: ${rem(8)};
`

interface Props extends PropsWithChildren {
  title: string
}

function FooterList({ children, title }: Props) {
  return <VerticalSpacer gap={12}>
    <ListTitle variant="large" weight="bold">{title}</ListTitle>
    <div>{children}</div>
  </VerticalSpacer>
}

export default FooterList
