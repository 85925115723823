import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import LineFacebookIcon from 'components/Luxkit/Icons/line/LineFacebookIcon'
import LineInstagramIcon from 'components/Luxkit/Icons/line/LineInstagramIcon'
import LineYoutubeIcon from 'components/Luxkit/Icons/line/LineYoutubeIcon'
import SolidTwitterIcon from 'components/Luxkit/Icons/solid/SolidTwitterIcon'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'

const IconsButton = styled(ClickableLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(46)};
  width: ${rem(46)};
  color: ${props => props.theme.palette.neutral.default.one};
  border-radius: ${props => props.theme.borderRadius.round};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};

  @media print {
    &[href]::after {
      content: " (" attr(href) ")";
    }
  }
`

function FollowUsLinks() {
  return (
    <VerticalSpacer gap={16}>
      <BodyText variant="large" as="h4" weight="bold" align="center" tabletAlign="start">
        Follow us
      </BodyText>
      <Group direction="horizontal" wrap="wrap" gap={8}>
        <IconsButton href="https://www.facebook.com/luxuryescapes" target="_blank" rel="noreferrer noopener">
          <LineFacebookIcon size="S" />
        </IconsButton>
        <IconsButton href="https://www.instagram.com/luxury.escapes/" target="_blank" rel="noreferrer noopener">
          <LineInstagramIcon size="S" />
        </IconsButton>
        <IconsButton href="https://www.youtube.com/channel/UCm7SgjHCUgYmgMv0uQex0mQ" target="_blank" rel="noreferrer noopener">
          <LineYoutubeIcon size="S" />
        </IconsButton>
        <IconsButton href="https://twitter.com/lux_escapes" target="_blank" rel="noreferrer noopener">
          <SolidTwitterIcon size="S" />
        </IconsButton>
      </Group>
    </VerticalSpacer>
  )
}

export default React.memo(FollowUsLinks)
