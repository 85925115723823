import React from 'react'
import AgentHubGuestFooter from './AgentHubGuestFooter'

function AgentHubFooter() {
  return (
    <AgentHubGuestFooter />
  )
}

export default AgentHubFooter
