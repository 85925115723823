import ListItem from 'components/Luxkit/List/ListItem'
import { rem } from 'polished'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

const Container = styled(ListItem)`
  background-color: transparent;
  padding-inline: ${rem(8)};
`

interface Props extends Omit<ComponentProps<typeof ListItem>, 'size'> {
}

function FooterListItem(props: Props) {
  return <Container {...props} size="medium" forwardedAs="li" />
}

export default FooterListItem
