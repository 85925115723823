import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function SolidTwitterIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M8.968 12.9875a1 1 0 0 0 .0214-1.2623l-5.7176-7.274c-.5157-.656-.0483-1.6179.7862-1.6179h3.3902a1 1 0 0 1 .7865.3825l3.5655 4.5417c.3916.4989 1.1427.5118 1.5514.0268l3.8492-4.5688a.9998.9998 0 0 1 .7647-.3557h.6549c.85 0 1.3124.9935.7651 1.644l-4.7224 5.6118a1 1 0 0 0-.0215 1.2613l6.0886 7.7556c.5151.6562.0476 1.6175-.7866 1.6175h-3.3744a1 1 0 0 1-.7862-.382l-3.9479-5.0225c-.3919-.4986-1.143-.5111-1.5513-.0259l-4.2551 5.0566a1 1 0 0 1-.7652.3561H4.593c-.8504 0-1.3127-.994-.7648-1.6443l5.1397-6.1005Zm8.2488 5.9964a.5579.5579 0 0 1-.4387-.2131L6.3549 5.5104c-.2904-.3694-.0272-.911.4427-.911a.563.563 0 0 1 .4428.2153l10.4152 13.2668c.2874.3661.0266.9024-.4388.9024Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default SolidTwitterIcon
