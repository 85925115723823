import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineYoutubeIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M23 9.71a8.4987 8.4987 0 0 0-.91-4.13 2.92 2.92 0 0 0-1.72-1A78.3613 78.3613 0 0 0 12 4.27a78.4501 78.4501 0 0 0-8.34.3 2.8701 2.8701 0 0 0-1.46.74c-.9.83-1 2.25-1.1 3.45a48.2893 48.2893 0 0 0 0 6.48 9.55 9.55 0 0 0 .3 2 3.14 3.14 0 0 0 .71 1.36 2.8604 2.8604 0 0 0 1.49.78 45.1831 45.1831 0 0 0 6.5.33c3.5.05 6.57 0 10.2-.28a2.8804 2.8804 0 0 0 1.53-.78c.28-.2801.4891-.6229.61-1a10.5777 10.5777 0 0 0 .52-3.4c.04-.56.04-3.94.04-4.54ZM9.74 14.85V8.66l5.92 3.11c-1.66.92-3.85 1.96-5.92 3.08Z"/>
  </SvgIcon>
}

export default LineYoutubeIcon
