import React, { ComponentProps } from 'react'
import FooterList from './FooterList'
import FooterListItem from './FooterListItem'

type InheritedFooterListProps = Pick<ComponentProps<typeof FooterList>, 'title'>

interface Props extends InheritedFooterListProps {
  title: string
  items: Array<App.PageConfig>
}

function FooterItemList({ title, items }: Props) {
  return <FooterList title={title}>
    {items.map((item) => <FooterListItem
      key={item.text}
      title={item.text}
      to={item.url?.to}
      href={item.url?.href}
      target={item.url?.target}
    />)}
  </FooterList>
}

export default FooterItemList
