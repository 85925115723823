import LayoutContainer from 'components/Common/LayoutContainer'
import Pane from 'components/Common/Pane'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LineEnvelopeIcon from 'components/Luxkit/Icons/line/LineEnvelopeIcon'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import config from 'constants/config'
import { NO_24_7_SUPPORT_REGIONS } from 'constants/config/region'
import { SUPPORT_EMAIL_FOR_BRAND } from 'constants/support'
import ContactContext from 'contexts/contactContext'
import GeoContext from 'contexts/geoContext'
import getStaticPagesByType from 'lib/utils/getStaticPagesByType'
import { rem } from 'polished'
import React, { useContext } from 'react'
import styled from 'styled-components'
import Accreditations from '../Accreditations'
import FooterItemList from '../FooterItemList'
import FooterList from '../FooterList'
import FooterListItem from '../FooterListItem'
import FooterListItemsGrid from '../FooterListItemsGrid'

const Container = styled(Pane)`
  border-top: 1px solid ${props => props.theme.palette.neutral.default.five};
  padding-block: ${rem(40)};
`

const OfficeLocation = styled(BodyText)`
  padding-inline: ${rem(8)};
`

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.palette.neutral.default.five};
  border: none;
`

const businessTravellerItemTypes = [
  'aboutUs',
]
const businessTravellerHelpItemTypes = [
  'termsAndConditions',
  'privacyPolicy',
  'businessFaq',
]

function BusinessTravellerFooter() {
  const geoContact = useContext(ContactContext)

  const businessTravellerItems = getStaticPagesByType(businessTravellerItemTypes)
  const businessTravellerHelpItems = getStaticPagesByType(businessTravellerHelpItemTypes)

  const currentYear = new Date().getFullYear()
  const { currentRegionCode } = useContext(GeoContext)
  const showAtolProtection = currentRegionCode === 'GB'

  const contact = geoContact.local
  const internationalContact = geoContact.international

  return (
    <Container type="light-grey" className="hidden-print">
      <LayoutContainer>
        <VerticalSpacer gap={56}>
          <FooterListItemsGrid>
            <FooterList title={NO_24_7_SUPPORT_REGIONS.includes(currentRegionCode) ? 'Customer support' : '24-7 Customer support'}>
              <FooterListItem
                href={`tel:${contact.number}`}
                rel="nofollow"
                title="Sales & Support"
                subtitle={contact.humanReadable}
                aria-label="Sales & Support"
                startIcon={<LinePhoneIcon />}
              />
              <FooterListItem
                href={`tel:${internationalContact.number}`}
                aria-label="International Support Centre contact"
                rel="nofollow"
                title="International Support Centre"
                subtitle={internationalContact.humanReadable}
                startIcon={<LinePhoneIcon />}
              />
              <FooterListItem
                href={`mailto:${SUPPORT_EMAIL_FOR_BRAND[config.BRAND]}`}
                aria-label="Email"
                rel="nofollow"
                title="Email"
                subtitle={SUPPORT_EMAIL_FOR_BRAND[config.BRAND]}
                startIcon={<LineEnvelopeIcon />}
              />
            </FooterList>
            <FooterItemList title="Business Traveller" items={businessTravellerItems} />
            <FooterItemList title="Help" items={businessTravellerHelpItems}/>
            <FooterList title="Head Office">
              <OfficeLocation variant="medium">
                Level 4, 68 Clarke Street, Southbank, VIC{'\u00a0'}3006.
                <br />
                Australian owned and operated.
              </OfficeLocation>
            </FooterList>
          </FooterListItemsGrid>
          <Divider/>
          <Group direction="vertical" tabletDirection="horizontal" gap={32} tabletHorizontalAlign="space-between" verticalAlign="center">
            <Accreditations md={8} showAtolProtection={showAtolProtection} showProductReviewWinner={false}/>
            <Group direction="vertical" horizontalAlign="center" tabletHorizontalAlign="end">
              <BodyText variant="small">Copyright © {currentYear} LE Business Traveller</BodyText>
              <BodyText variant="small">All rights reserved.</BodyText>
            </Group>
          </Group>
        </VerticalSpacer>
      </LayoutContainer>
    </Container>
  )
}

export default BusinessTravellerFooter
