import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import Image from 'components/Common/Image'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { ProductReviewLogo } from 'components/Common/TrustIndicators/ProductReviewLogo'

const Root = styled.div`
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${rem(34)};

  ${mediaQueryUp.desktop} {
    justify-content: 'stretch';
    align-items: center;
    flex-wrap: nowrap;
  }
`

const Accreditation = styled(Image)`
  width: auto;
  height: ${rem(64)};
`

interface Props {
  showAtolProtection: boolean;
  showProductReviewWinner: boolean;
  md?: number;
}

function Accreditations({ showAtolProtection, showProductReviewWinner, ...props }: Props) {
  return (
    <Root {...props}>
      <Accreditation
        format="png"
        id="IATA_nsg6d6"
        alt="IATA"
      />
      <Accreditation
        format="png"
        id="x2rsn9482n5gxo9jylyi"
        alt="ATIA"
      />
      <Accreditation
        format="png"
        id="cc12ek625kle4vj6yvvj"
        alt="CATO"
      />
      {showAtolProtection && <Accreditation
        format="jpg"
        id="atol-11711-logo"
        alt="ATOL logo"
      />}
      {showProductReviewWinner && <ProductReviewLogo width="200" />}
    </Root>
  )
}

export default Accreditations
