import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineInstagramIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M17.34 5.46a1.2003 1.2003 0 0 0-1.1087.7408 1.2002 1.2002 0 0 0 1.5679 1.5679A1.2003 1.2003 0 0 0 18.54 6.66a1.1998 1.1998 0 0 0-1.2-1.2Zm4.6 2.42a7.5875 7.5875 0 0 0-.46-2.43 4.9402 4.9402 0 0 0-1.16-1.77 4.7 4.7 0 0 0-1.77-1.15 7.3 7.3 0 0 0-2.43-.47C15.06 2 14.72 2 12 2s-3.06 0-4.12.06a7.3 7.3 0 0 0-2.43.47 4.78 4.78 0 0 0-1.77 1.15 4.7001 4.7001 0 0 0-1.15 1.77 7.3 7.3 0 0 0-.47 2.43C2 8.94 2 9.28 2 12s0 3.06.06 4.12a7.3 7.3 0 0 0 .47 2.43 4.7 4.7 0 0 0 1.15 1.77 4.7799 4.7799 0 0 0 1.77 1.15 7.3007 7.3007 0 0 0 2.43.47C8.94 22 9.28 22 12 22s3.06 0 4.12-.06a7.3008 7.3008 0 0 0 2.43-.47 4.6998 4.6998 0 0 0 1.77-1.15 4.8508 4.8508 0 0 0 1.16-1.77 7.5875 7.5875 0 0 0 .46-2.43c0-1.06.06-1.4.06-4.12s0-3.06-.06-4.12ZM20.14 16a5.6092 5.6092 0 0 1-.34 1.86 3.0603 3.0603 0 0 1-.75 1.15 3.1911 3.1911 0 0 1-1.15.75 5.6092 5.6092 0 0 1-1.86.34c-1 .05-1.37.06-4 .06s-3 0-4-.06a5.7298 5.7298 0 0 1-1.94-.3 3.2695 3.2695 0 0 1-1.1-.75 3.0003 3.0003 0 0 1-.74-1.15 5.54 5.54 0 0 1-.4-1.9c0-1-.06-1.37-.06-4s0-3 .06-4a5.54 5.54 0 0 1 .35-1.9A3.001 3.001 0 0 1 5 5a3.14 3.14 0 0 1 1.1-.8A5.73 5.73 0 0 1 8 3.86c1 0 1.37-.06 4-.06s3 0 4 .06a5.61 5.61 0 0 1 1.86.34 3.0602 3.0602 0 0 1 1.19.8c.3277.3072.5838.6827.75 1.1a5.6086 5.6086 0 0 1 .34 1.9c.05 1 .06 1.37.06 4s-.01 3-.06 4ZM12 6.87A5.13 5.13 0 1 0 17.14 12a5.1194 5.1194 0 0 0-1.5031-3.634A5.1208 5.1208 0 0 0 12 6.87Zm0 8.46a3.3299 3.3299 0 0 1-2.3547-5.6847A3.33 3.33 0 1 1 12 15.33Z"/>
  </SvgIcon>
}

export default LineInstagramIcon
