import React, { useMemo, useState } from 'react'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import styled from 'styled-components'
import LayoutContainer from 'components/Common/LayoutContainer'
import { mediaQueryUp } from 'components/utils/breakpoint'
import TopDestinations from 'components/Common/TopDestinationsList/TopDestinations'
import Heading from 'components/Luxkit/Typography/Heading'
import TabButtonsGroup from 'components/Luxkit/Tabs/TabButtonsGroup'
import TabButton from 'components/Luxkit/Tabs/TabButton'
import VerticalSpacer from '../Spacing/VerticalSpacer'
import config from 'constants/config'
import { rem } from 'polished'

interface DestinationBase {
  name: string;
  level: string;
  link?: string;
  alternativeNameForLink?: string;
}

export interface CountryDestination extends DestinationBase {}

export interface CityDestination extends DestinationBase {
  higherLevel: string;
  country: string;
}

const transformDestinationNameForLink = (destinationName: string) => {
  return destinationName.replace(/-+/g, '+').replace(/\s+/g, '-').toLowerCase()
}
const addLinksToDestinations = (destinationArray: Array<CountryDestination | CityDestination>) => {
  return destinationArray.forEach((destination) => {
    const destinationType = destination.level
    const name = destination.alternativeNameForLink ? transformDestinationNameForLink(destination.alternativeNameForLink) : transformDestinationNameForLink(destination.name)
    let higherDestinationLevels = ''
    if ('higherLevel' in destination) {
      if (destination.higherLevel) higherDestinationLevels = higherDestinationLevels + '/' + transformDestinationNameForLink(destination.higherLevel)
    }
    if ('country' in destination) {
      if (destination.country) higherDestinationLevels = higherDestinationLevels + '/' + transformDestinationNameForLink(destination.country)
    }

    const link = `/hotels/${destinationType}/${name}${higherDestinationLevels}`
    destination.link = link
  })
}

const Root = styled.div`
  display: flex;
  text-align: left;

  ${mediaQueryUp.tablet} {
    display: block;
  }
`

const StyledLayoutContainer = styled(LayoutContainer)`
  padding-top: ${rem(16)};
`

export type DestinationNavItemType = 'Countries' | 'Cities' | 'Popular'

// Init inside a function to ensure that config object is properly created
const initDestinationLinks = () => {
  addLinksToDestinations(config.topDestinationList.countries)
  addLinksToDestinations(config.topDestinationList.cities)
  addLinksToDestinations(config.topDestinationList.popular)
}
initDestinationLinks()

interface Props {
  destinationNavItems?: Array<DestinationNavItemType>;
}

const defaultItems: Array<DestinationNavItemType> = ['Countries', 'Cities', 'Popular']
function TopDestinationList({ destinationNavItems = defaultItems }: Props) {
  const [activeNavItem, setActiveNavItem] = useState<DestinationNavItemType>(destinationNavItems[0])

  const destinations = useMemo(() => {
    if (activeNavItem === 'Countries') {
      return config.topDestinationList.countries
    } else if (activeNavItem === 'Cities') {
      return config.topDestinationList.cities
    } else {
      // must be popular
      return config.topDestinationList.popular
    }
  }, [activeNavItem])

  return (<Root>
    <StyledLayoutContainer>
      <VerticalSpacer gap={16}>
        <Heading variant="heading3">Explore top hotel destinations we love</Heading>
        <TabButtonsGroup>
          {destinationNavItems.map((nav) => {
            return <TabButton
              key={nav}
              size="medium"
              isActive={nav === activeNavItem}
              onClick={() => { setActiveNavItem(nav) }}
            >
              {nav}
            </TabButton>
          })}
        </TabButtonsGroup>
        <CSSBreakpoint max="tablet">
          <TopDestinations destinations={destinations} toShowByDefault={6} destinationType={activeNavItem} />
        </CSSBreakpoint>
        <CSSBreakpoint min="desktop">
          <TopDestinations destinations={destinations} toShowByDefault={12} destinationType={activeNavItem} />
        </CSSBreakpoint>
      </VerticalSpacer>
    </StyledLayoutContainer>
  </Root>)
}

export default React.memo(TopDestinationList)
