import LayoutContainer from 'components/Common/LayoutContainer'
import Pane from 'components/Common/Pane'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Divider from 'components/Luxkit/Divider'
import LineWhatsappIcon from 'components/Luxkit/Icons/line/LineWhatsappIcon'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import OfficeLocationModal, { OFFICE_LOCATION_CONTENT, OfficeLocations } from 'components/Pages/AboutUsPage/OfficeLocationModal/OfficeLocationModal'
import Group from 'components/utils/Group'
import config from 'constants/config'
import { NO_24_7_SUPPORT_REGIONS, PARTNER_BRANDS_ENABLED_REGIONS } from 'constants/config/region'
import ModalContext from 'contexts/ModalContext'
import GeoContext from 'contexts/geoContext'
import { useRenderWhatsApp } from 'hooks/useRenderWhatsApp'
import { getSVGImageUrl } from 'lib/image/imageUtils'
import shouldRenderTimely from 'lib/timely/timely'
import getStaticPagesByType from 'lib/utils/getStaticPagesByType'
import { rem } from 'polished'
import React, { useCallback, useContext, useRef } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Accreditations from './Accreditations'
import FooterItemList from './FooterItemList'
import FooterListItem from './FooterListItem'
import FooterListItemsGrid from './FooterListItemsGrid'
import FooterList from './FooterList'
import LineCommentsIcon from 'components/Luxkit/Icons/line/LineCommentsIcon'
import { useAppSelector } from 'hooks/reduxHooks'
import { isSupportAssistantEnabled } from 'selectors/featuresSelectors'
import { useAppFooterObserver } from 'hooks/useAppLayoutObservers'

const Container = styled(Pane)`
  padding-block: ${rem(40)};
`

const StoreLocation = styled(BodyText)`
  padding-inline: ${rem(8)};
`

const luxuryEscapesItems = getStaticPagesByType([
  'aboutUs',
  'bestPrice',
  'careersAndCulture',
  'becomePartner',
  'affiliatePage',
]).filter(page => !!page.url)

const helpItems = getStaticPagesByType([
  'termsAndConditions',
  'privacyPolicy',
  'giftcardTermsAndConditions',
  'refundPolicy',
  'support',
]).filter(page => !!page.url)

const otherPartners = getStaticPagesByType([
  'scoopon',
  'cudo',
  'deals',
  'treatme',
  'catch',
  'travelshoot',
]).filter(page => !!page.url)

interface Props {
  isStoreMode: boolean;
  region: string;
}

function BottomSection(props: Props) {
  const { isStoreMode } = props

  const currentYear = new Date().getFullYear()
  const { currentRegionCode } = useContext(GeoContext)
  const { offices } = OFFICE_LOCATION_CONTENT
  const showOtherPartners = !isStoreMode &&
    config.businessTraveller.currentAccountMode !== 'business' &&
    PARTNER_BRANDS_ENABLED_REGIONS.includes(currentRegionCode)

  const shouldRenderWhatsApp = useRenderWhatsApp(currentRegionCode)

  const showModal = useContext(ModalContext)
  const toggleModal = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const selectedOfficeLocation = e.currentTarget.dataset.key as OfficeLocations
    showModal(<OfficeLocationModal selectedOffice={selectedOfficeLocation} />)
  }, [showModal])

  const showAtolProtection = currentRegionCode === 'GB'

  const supportAssistantEnabled = useAppSelector(isSupportAssistantEnabled)
  const shouldShowSupportSection = supportAssistantEnabled || shouldRenderWhatsApp || shouldRenderTimely(currentRegionCode)

  const containerRef = useRef<HTMLDivElement>(null)
  useAppFooterObserver(containerRef)

  return <Container type="light" className="hidden-print" ref={containerRef}>
    <LayoutContainer variant="large">
      <VerticalSpacer gap={56}>
        <FooterListItemsGrid>
          {shouldShowSupportSection && <FooterList title={NO_24_7_SUPPORT_REGIONS.includes(currentRegionCode) ? 'Customer support' : '24-7 Customer support'}>
            {supportAssistantEnabled && <FooterListItem
              to="/support"
              rel="nofollow"
              title="Chat now"
              subtitle="Available 24/7"
              startIcon={<LineCommentsIcon />}
            />}
            {shouldRenderWhatsApp && <FooterListItem
              title="WhatsApp"
              startIcon={<LineWhatsappIcon />}
              href={`https://wa.me/${config.WHATS_APP_NUMBER}`}
              aria-label="Whats App"
              rel="noopener"
              target="_blank"
            />}
            {shouldRenderTimely(currentRegionCode) && <FooterListItem
              title="Book store appointment"
              startIcon={<img src={getSVGImageUrl('logo-LE')} width="24" height="24" alt="LE store" />}
              href={config.TIMELY_LINK}
              aria-label="Timely booking"
              rel="noopener"
              target="_blank"
            />}
          </FooterList>}
          <FooterItemList title="LuxuryEscapes.com" items={luxuryEscapesItems} />
          <FooterItemList title="Help" items={helpItems}/>
          {showOtherPartners && <FooterItemList title="Our Partner Brands" items={otherPartners}/>}
          {isStoreMode && <FooterItemList title="Luxury Escapes Locations" items={helpItems}/>}
          {!isStoreMode && <FooterList title="Luxury Escapes Locations">
            {offices.map((office) => <FooterListItem
              key={office.location}
              title={office.location}
              data-key={office.location}
              onClick={toggleModal}
            />)}
          </FooterList>}
          {isStoreMode && <FooterList title="Luxury Escapes Store">
            <StoreLocation variant="medium">
              Shop B-186, Chadstone Shopping Centre
              <br />
              1341 Dandenong Road,
              <br />
              Chadstone VIC 3148
              <br /><br />
              Australian owned and operated.
            </StoreLocation>
          </FooterList>}
        </FooterListItemsGrid>
        {showAtolProtection && <VerticalSpacer gap={12}>
          <BodyText variant="large" weight="bold">ATOL Protection</BodyText>
          <BodyText variant="medium" align="justify">
            Some of the flights and flight-inclusive holidays on this website are financially protected by the ATOL scheme.{' '}
            But ATOL protection does not apply to all holiday and travel services listed on this website.{' '}
            This website will provide you with information on the protection that applies in the case of each holiday and travel service offered before you make your booking.{' '}
            If you do not receive an ATOL Certificate then the booking will not be ATOL protected. If you do receive an ATOL Certificate{' '}
            but all the parts of your trip are not listed on it, those parts will not be ATOL protected.{' '}
            Please see our booking conditions for information, or for more information about financial protection and the ATOL Certificate go to:{' '}
            <TextLink weight="regular" href="https://www.caa.co.uk/" target="_blank">www.caa.co.uk</TextLink>
          </BodyText>
        </VerticalSpacer>}
        <Divider kind="primary" />
        <Group
          direction="vertical"
          gap={32}
          tabletDirection="horizontal"
          tabletVerticalAlign="center"
          tabletHorizontalAlign="space-between"
        >
          {!isStoreMode && <Accreditations md={8} showAtolProtection={showAtolProtection} showProductReviewWinner />}
          <BodyText variant="small" align="center" tabletAlign="end">
            <small>Copyright © 2017 - {currentYear} Luxury Escapes.</small>
            <small>All rights reserved</small>
          </BodyText>
        </Group>
      </VerticalSpacer>
    </LayoutContainer>
  </Container>
}

function mapStateToProps(state: App.State) {
  return {
    isStoreMode: state.config.storeMode,
    region: state.geo.currentRegionCode,
  }
}

export default connect(mapStateToProps)(BottomSection)
